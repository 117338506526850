<template>
  <main>
    <h1 class="font-medium mt-5">Online Catalogue</h1>
    <trac-loading v-if="isLoading" />
    <div
      class="flex items-center flex-col justify-center mt-48"
      v-if="user.user"
    >
      <div class="w-full max-w-md border p-8 text-center">
        <div class="content" v-if="user.user.online_store">
          <p class="pb-3">Online store url</p>

          <a
            class="text-primaryBlue"
            target="_blank"
            :href="'https://' + user.user.online_store_url"
            >{{ "https://" + user.user.online_store_url }}</a
          >
        </div>
        <p class="text-sm" v-else>
          Create an online version of your store and receive orders from
          customers.
        </p>
      </div>
      <trac-button
        data-test="deactivate-button"
        @click.native="toggleStore"
        :danger="user.user.online_store"
        class="mt-8"
      >
        <span>{{ user.user.online_store ? "De-activate" : "Activate" }}</span>
      </trac-button>
    </div>
  </main>
</template>

<script>
import { GET_USER_DATA, GET_USER_BUSINESS_ID, GET_LOCAL_DB_DATA, SAVE_LOCAL_DB_DATA } from '../../browser-db-config/localStorage'
export default {
  data() {
    return {
      isLoading: false,
      user: {},
    }
  },
  methods: {
    async toggleStore() {
      this.isLoading = true
      let k = GET_LOCAL_DB_DATA('traction-app-user-data');

      let res = await this.$store.dispatch('TOGGLE_ONLINE_STORE', {
        business: GET_USER_BUSINESS_ID()
      });
      if (res.status) {
        this.isLoading = false
        let b = {
          ...k, user: {
            ...k.user, online_store: res.data.store_status, online_store_url: res.data.url
          }
        }
        SAVE_LOCAL_DB_DATA('traction-app-user-data', b);
        this.user = GET_LOCAL_DB_DATA('traction-app-user-data');
      }
    }
  },
  mounted() {
    this.user = GET_LOCAL_DB_DATA('traction-app-user-data');
  }


}
</script>

<style>
</style>